<template>
	<div class="view-content__wrapper">
		<div class="view-content view-content--wide d-block d-sm-flex">
			<div class="verify-data mx-auto">
				<div class="invite-teacher__header">
					<img class="invite-teacher__logo" v-if="$vuetify.theme.dark" src="../assets/Teacher_invite_dark.svg" alt="eKool logo">
					<img class="invite-teacher__logo" v-else src="../assets/Teacher_invite.svg" alt="eKool logo">
					<h1
						class="headline-1 graybase--text mb-1 text-center"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("teacher_invite_title",{school: teacherInviteInfo.school_name}) }}
					</h1>
					<p
						class="body-3 text-center mb-0"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
						{{ $t("teacher_invite_desc_1",{email: teacherInviteInfo.creator,school: teacherInviteInfo.school_name}) }}
					</p>
					<p
						class="body-3 text-center mb-0"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
						{{ $t("teacher_invite_desc_2") }}
					</p>
				</div>
				<div class="invite-teacher__footer">
					<e-button
						variant="primary"
						:block="true"
						size="large"
						large
						@click="acceptInvitation()">
						{{ $t("teacher_invite_accept") }}
					</e-button>
					<e-button
						variant="ghost"
						:block="true"
						size="large"
						large
						@click="declineInvitation()">
						{{ $t("teacher_invite_decline") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data () {
		return {
      teacherInviteInfoFail: false,
		};
	},
	computed: {
		...mapGetters([
			'getTeacherInviteInfoBusy',
			'getTeacherInviteInfoSuccess',
      'teacherInviteInfo',
      'sendTeacherInviteStatusBusy',
      'sendTeacherInviteStatusSuccess',
      'teacherInviteStatus'
		]),
	},
	mounted () {
this.$store.dispatch('getInviteInfo', { hash: this.$route.query.hash }).then(() => {
  if (!this.teacherInviteInfo) {
    this.$router.push({
            name: 'teacherinviteerror', 
            params: { lang: this.$route.params.lang }
          });
  }
	})
  },
	methods: {
		acceptInvitation () {
      this.$store.dispatch('sendInviteStatus', { hash: this.$route.query.hash, status: 1 }).then(() => {
        if (this.teacherInviteStatus) {
          this.$router.push({
            name: 'teacherinviteaccept', 
            params: { lang: this.$route.params.lang }
          });
        }
        else {
         this.$eToast(this.$i18n.t(`teacher_invite_error_toast`));
        }
      }
      )
		},
		declineInvitation () {
      this.$store.dispatch('sendInviteStatus', { hash: this.$route.query.hash, status: 0 }).then(() => {
        if (this.teacherInviteStatus) {
          this.$router.push({
            name: 'teacherinvitedecline', 
            params: { lang: this.$route.params.lang }
          });
        }
        else {
         this.$eToast(this.$i18n.t(`teacher_invite_error_toast`));
        }
      }
      )
		},
	},
};
</script>