import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'

Vue.use(Vuex)

export default{
	state: {
		getEmailVerifyBusy: false,
		getEmailVerifySuccess: false,
		emailVerifyStatus: undefined,
    getEmailUpdateBusy: false,
		getEmailUpdateSuccess: false,
		emailUpdateStatus: undefined,
	},
	mutations: {
		// email verify
		getEmailVerifyStatus (state){
			state.getEmailVerifyBusy = true;
		},
		getEmailVerifyStatusSuccess (state){
			state.getEmailVerifyBusy = false;
			state.getEmailVerifySuccess = true;
			state.emailVerifyStatus = true;
		},
		getEmailVerifyStatusFail (state){
			state.getEmailVerifyBusy = false;
			state.emailVerifyStatus = false;
		},
    //email update
    getEmailUpdateStatus (state){
			state.getEmailUpdateBusy = true;
		},
		getEmailUpdateStatusSuccess (state){
			state.getEmailUpdateBusy= false;
			state.getEmailUpdateSuccess = true;
			state.emailUpdateStatus = true;
		},
		getEmailUpdateStatusFail (state){
			state.getEmailUpdateBusy = false;
			state.emailUpdateStatus = false;
		},
	},
	actions: {
		getEmailVerifyStatus: (store, { hash }) => {
			store.commit('getEmailVerifyStatus')
			return api.emailVerifyLink(hash)
				.then(
					response => {
						store.commit('getEmailVerifyStatusSuccess')
						return response;
					},
					error => {
						store.commit('getEmailVerifyStatusFail', error)
						return error;
					},
				)
		},
    getEmailUpdateStatus: (store, { hash }) => {
			store.commit('getEmailUpdateStatus')
			return api.emailUpdateLink(hash)
				.then(
					response => {
						store.commit('getEmailUpdateStatusSuccess')
						return response;
					},
					error => {
						store.commit('getEmailUpdateStatusFail', error)
						return error;
					},
				)
		},
	},
	getters: {
    getEmailVerifyBusy: state => state.getEmailVerifyBusy,
    getEmailVerifySuccess: state => state.getEmailVerifySuccess,
    emailVerifyStatus: state => state.emailVerifyStatus,
    getEmailUpdateBusy: state => state.getEmailUpdateBusy,
    getEmailUpdateSuccess: state => state.getEmailUpdateSuccess,
    emailUpdateStatus: state => state.emailUpdateStatus,
	},
}