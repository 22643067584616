import Vue from "vue";
import Vuex from "vuex";

import password from "./modules/password";
import forgot from "./modules/forgot";
import smart from "./modules/smart";
import mobile from "./modules/mobile";
import card from "./modules/card";
import setupAccount from "./modules/setupAccount";
import newPassword from "./modules/newPassword";
import chooseUser from "./modules/chooseUser";
import schoolRegister from "./modules/schoolRegister";
import har from "./modules/har";
import xid from "./modules/xid";
import thirdPartyPermissions from "./modules/thirdPartyPermissions";
import changePassword from "./modules/changePassword";
import adminChangePassword from "./modules/adminChangePassword";
import layout from "./modules/layout"
import roles from "./modules/roles";
import terms from "./modules/terms";
import verifyData from "./modules/verifyData";
import cfTurnstile from "./modules/cfTurnstile";
import thirdParty from "./modules/thirdParty";
import teacherInvite from "./modules/teacherInvite";
import emailValidation from "./modules/emailValidation";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		error: {
			showError: false,
			errorContent: "",
		},
		miniView: false,
		mobileView: false,
		miniViewFunction: () => { },
	},
	getters: {
		getError: state => {
			return state.error.showError;
		},
		getErrorContent : state => {
			return state.error.errorContent;
		},
	},
	mutations: {
		showError (state, value) {
			state.error.showError = value;
			//setTimeout(state.error.showError = !state.error.showError, 3000);
		},
		changeError (state, message) {
			// console.trace()
			state.error.errorContent = message;
		},
		setMiniView (state, value) {
			state.miniView = value;
		},
		setMobileView (state, value) {
			state.mobileView = value;
		},
		setMiniViewFunction (state, value) {
			state.miniViewFunction = value;
		},

	},
	actions: {},
	modules: {
		password,
		forgot,
		smart,
		mobile,
		card,
		setupAccount,
		newPassword,
		chooseUser,
		schoolRegister,
		har,
		xid,
		thirdPartyPermissions,
		changePassword,
		adminChangePassword,
		layout,
		roles,
		terms,
		verifyData,
		cfTurnstile,
		thirdParty,
		teacherInvite,
		emailValidation,
	},
});
