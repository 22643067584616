import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'

Vue.use(Vuex)

export default{
	state: {
		getTeacherInviteInfoBusy: false,
		getTeacherInviteInfoSuccess: false,
		teacherInviteInfo: undefined,
    sendTeacherInviteStatusBusy: false,
    sendTeacherInviteStatusSuccess: false,
    teacherInviteStatus: undefined,
	},
	mutations: {
		//get invite info
		getInviteInfo (state){
			state.getTeacherInviteInfoBusy = true;
		},
		getInviteInfoSuccess (state,response){
			state.getTeacherInviteInfoBusy = false;
			state.getTeacherInviteInfoSuccess = true;
			state.teacherInviteInfo = response.data.data;
		},
		getInviteInfoFail (state){
			state.getTeacherInviteInfoBusy = false;
			state.teacherInviteInfo = undefined;
		},
    //send invite status
    sendInviteStatus (state){
      state.sendTeacherInviteStatusBusy = true;
    },
    sendInviteStatusSuccess (state){
      state.sendTeacherInviteStatusBusy = false;
      state.sendTeacherInviteStatusSuccess = true;
      state.teacherInviteStatus = true;
    },
    sendInviteStatusFail (state){
      state.sendTeacherInviteStatusBusy = false;
      state.sendTeacherInviteStatusSuccess = false;
      state.teacherInviteStatus = false;
    },
	},
	actions: {
		getInviteInfo: (store, { hash }) => {
			store.commit('getInviteInfo')
			return api.getTeacherInviteInfo(hash)
				.then(
					response => {
						store.commit('getInviteInfoSuccess',response)
						return response;
					},
					error => {
						store.commit('getInviteInfoFail', error)
						return error;
					},
				)
		},
    sendInviteStatus: (store, { hash,status }) => {
      store.commit('sendInviteStatus')
      return api.sendTeacherInviteStatus(hash,status)
        .then(
          response => {
            store.commit('sendInviteStatusSuccess',response)
            return response;
          },
          error => {
            store.commit('sendInviteStatusFail', error)
            return error;
          },
        )
    },
	},
	getters: {
		getTeacherInviteInfoBusy: state => state.getTeacherInviteInfoBusy,
		getTeacherInviteInfoSuccess: state => state.getTeacherInviteInfoSuccess,
		teacherInviteInfo: state => state.teacherInviteInfo,
    sendTeacherInviteStatusBusy: state => state.sendTeacherInviteStatusBusy,
    sendTeacherInviteStatusSuccess: state => state.sendTeacherInviteStatusSuccess,
    teacherInviteStatus: state => state.teacherInviteStatus,
	},
}