<template>
	<div class="view-content__wrapper">
		<div class="view-content view-content--wide d-block d-sm-flex">
			<div class="verify-data mx-auto">
				<div class="invite-teacher__header">
					<img class="invite-teacher__logo" v-if="$vuetify.theme.dark" src="../assets/Teacher_invite_declined_dark.svg" alt="eKool logo">
					<img class="invite-teacher__logo" v-else src="../assets/Teacher_invite_declined.svg" alt="eKool logo">
					<h1
						class="headline-1 graybase--text mb-1 text-center"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("teacher_invite_declined_title") }}
					</h1>
					<p
						class="body-3 text-center mb-0"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
						{{ $t("teacher_invite_declined_desc", {school: teacherInviteInfo.school_name}) }}
					</p>
				</div>
				<div class="invite-teacher__footer">
					<e-button
						variant="primary"
						:block="true"
						size="large"
						large
						@click="goToEkool()">
						{{ $t("go_to_ekool") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getEnv } from "../env"
export default {
	data () {
		return {
		};
	},
	computed: {
		...mapGetters([
      'teacherInviteInfo',
		]),
	},
	methods: {
		goToEkool () {
			window.top.location.href = getEnv('VUE_APP_FRONTEND_APP_URL');
		},
	},
};
</script>